<template>
	<div class="register-box">
		<div class="register-right">
            <el-input v-model="company" placeholder="请输入企业名称" type="text" style="margin-bottom: 32px;" />
            <el-input v-model="phone" maxlength="11" placeholder="请输入手机号" @blur="test" type="text" style="margin-bottom: 32px;" />
			<div class="register-code">
				<input type="text" v-model="code" placeholder="输入验证码" maxlength="6">
				<span v-show="show" @click="getCode">获取验证码</span>
                <span v-show="!show">{{count}}s后</span>
			</div>
		   <el-input v-model="password" placeholder="请输入密码" type="password" show-password />
		   <div class="register-button" @click="register">注册</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'register',
		data() {
			return {
				company:'',
				phone:'',
				code:'',
				password:'',
				show:true,
				count:'',
                timer:null,
			}
		},
		methods: {
			tip(type, msg) {
			return this.$message({
				type: type,
				message: msg,
				showClose: true,
				duration: 1000
			})
			},
			getCode(){
				let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if (this.phone == '') {
					this.tip("error","请输入手机号")
				    return false
				}else if (!reg.test(this.phone)) {
					this.tip("error","手机号格式不正确")
					return false;
				}else {
					this.$http.post(this.GLOBAL.new_url + '/msg/smsMsg/sendSmsMsg', {
						 userPhone:this.phone,
						userCode:'',
						typeNum: 800000001, //是模板类型码
						params: '', //是模板需替换参数
						bigType: 0,
					}).then(res => {
						if (res.data.code == "200") {
							var TIME_COUNT = 60;
							if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
								this.count--;
								} else {
								this.show = true;
								clearInterval(this.timer);
								this.timer = null;
								}
							}, 1000)
							}
						} else {
							
						}
						})
				}
			},
			test(){
				let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if(this.phone != ''){
					if (!reg.test(this.phone)){
					this.tip("error","手机号格式不正确")
				  }else{
					this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/checkApplicantUser', {
				    phoneNumber: this.phone
				  }).then(res => {
				    // console.log(res)
				    if (res.data.code == "200") {
				  
				    } else {
						
				      this.tip('error', res.data.data);
					  this.phone = ""
				    }
				  })
				}
				}
			},
			register(){
				let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if(this.company==""){
					this.tip("error","请输入企业名称")
				return false;
				}else if (this.phone == '') {
				this.tip("error","请输入手机号")
				return false;
				} else if (!reg.test(this.phone)) {
				this.tip("error","手机号格式不正确")
				return false;
				}else if (this.code == "") {
				this.tip("error","请输入验证码")
				return false;
				} else if (this.password == '') {
				this.tip("error","请输入密码")
				return false;
				} else {
				  this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/applicantUserRegister', {
				    applicantUser: this.company,                          
					password:this.password,         
					phoneNumber: this.phone,
					code: this.code,                                
					province: this.$route.query.province,                 
					city: this.$route.query.city,
					county:this.$route.query.county,
					town:this.$route.query.town
				  }).then(res => {
				    // console.log(res)
				    if (res.data.code == "200") {
				      this.tip('success', "注册成功");
					  this.$router.push({
						path:'/intellectualProperty',
						query:{
							company:res.data.data.applicantUser
						}
					  })
				    } else {
				      this.tip('error', res.data.data);
				    }
				  })
				}
			}
		},
	}
</script>

<style scoped>
	.register-box {
		position: relative;
		height: 100vh;
		width: 100vw;
		background: url('../../../static/images/login-big.png') 100% no-repeat;
  		background-size: cover;
	}
	.register-right{
		width: 767px;
		height: 517px;
		padding-top: 70px;
		position: absolute;
		top:50%;
		right:8%;
		transform: translate(0,-50%);
		text-align: center;
		background: url('../../../static/images/login-input.png') 100% no-repeat;
	}
	:deep(.el-input__inner){
		width: 400px;
		outline: none;
		color: white;
		background: #007E66;
		padding-left: 10px;
		border:1px solid #54FFD6;
		height: 48px;
	}
	:deep(.el-input__suffix){
		margin-right: 185px;
	}
	.register-code{
		margin:0 auto 32px;
		width: 400px;
	}
	.register-code>input{
		width: 230px;
		height: 48px;
		padding-left: 10px;
		outline: none;
		margin-right: 32px;
		color: white;
		border-radius: 4px;
		background: #007E66;
		padding-left: 10px;
		border:1px solid #54FFD6;
	}
	.register-code>span{
		display: inline-block;
		width: 120px;
		border-radius: 4px;
		height: 46px;
		line-height: 48px;
		font-size: 14px;
		cursor: pointer;
		text-align: center;
		color: #FFFFFF;
		border:1px solid #54FFD6;
		background: transparent;
	}
	::-webkit-input-placeholder{
	color: #FFFFFF;
	opacity: .6;
}
.register-button{
	width: 400px;
		height: 60px;
		margin: 64px auto 0;
		background: #01C495;
		border-radius: 4px;
		cursor: pointer;
		line-height: 60px;
		text-align: center;
		color: #FFFFFF;
		font-size: 18px;
}
</style>
